@import url("https://fonts.googleapis.com/css?family=Roboto+Slab");
@import url("https://fonts.googleapis.com/css?family=Playfair+Display");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://fonts.googleapis.com/css?family=Gothic+A1");
@import url("https://fonts.googleapis.com/css?family=Roboto");

.roboto {
  font-family: "Roboto", sans-serif;
}

.gothic {
  font-family: "Gothic A1", sans-serif;
}

.roboto-slab {
  font-family: "Roboto Slab", serif;
}

.playfair {
  font-family: "Playfair Display", serif;
}

.carousel .slider-wrapper .slide {
  background: inherit !important;
}

.carousel.carousel-slider .slide {
  background: white;
}

.product-page .carousel .control-dots .dot {
  background: black;
}

.product-page .carousel.carousel-slider {
  height: 100%;
}

.product-page .product-images .carousel .control-dots {
  bottom: 40px;
}

.product-page ul.control-dots {
  padding-inline-start: 0px;
}

.h_iframe iframe {
  width: 100%;
  height: 100%;
}
.h_iframe {
  width: 100%;
  height: 360px;
}

.bg-secondary.shadow.mt-5.cover {
  background-size: cover;
  background-position-y: center;
}

.fancy {
  line-height: 0;
  text-align: center;
}
.fancy {
  display: inline-block;
  position: relative;
}
.fancy:before,
.fancy:after {
  content: "";
  position: absolute;
  height: 5px;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  top: 0;
  width: 100%;
}
.fancy:before {
  right: 100%;
  margin-right: 15px;
}
.fancy:after {
  left: 100%;
  margin-left: 15px;
}

.pointer {
  cursor: pointer;
}

.udi-page {
  background-color: #f7fde3;
  color: #504237;
  a {
    color: #504237;
    text-decoration: none;
  }

  .bg-udi-primary {
    background-color: #34b6e4;
  }

  .nutrition {
    border-radius: 40px;
  }

  .gluten-free {
    position: absolute;
    background-color: #504237;
    opacity: 0.75;
    bottom: 0px;
  }
}

.zig-zag-top:before {
  background: linear-gradient(
      -45deg,
      #34b6e4 16px,
      red 16px,
      blue 16px,
      transparent 0
    ),
    linear-gradient(45deg, #34b6e4 16px, transparent 0);
  background-position: left top;
  background-repeat: repeat-x;
  background-size: 22px 32px;
  content: " ";
  display: block;
  height: 32px;
  width: 100%;
  position: relative;
  bottom: 64px;
  left: 0;
}

.zig-zag-bottom {
  margin: 32px 0;
  margin-top: 0;
}

.zig-zag-top {
  margin: 32px 0;
  margin-bottom: 0;
}

.zig-zag-bottom,
.zig-zag-top {
  padding: 32px 0;
}

.zig-zag-bottom:after {
  background: linear-gradient(-45deg, transparent 16px, #34b6e4 0),
    linear-gradient(45deg, transparent 16px, #34b6e4 0);
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: 22px 32px;
  content: "";
  display: block;
  width: 100%;
  height: 32px;
  position: relative;
  top: 64px;
  left: 0px;
}

.mapMarkerStyle {
  background-color: red;
  width: 1px;
  height: 1px;
  display: block;
  left: -0.5rem;
  top: -0.5rem;
  position: relative;
  border-radius: 1.5rem 1.5rem 0;
  transform: rotate(45deg);
  border: 1px solid red;
}

.mapMarkerStyle::after {
  position: "absolute";
  content: "";
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-left: -4px;
  margin-top: -4px;
  background-color: red;
}

.fairdale-logistics .choice {
  position: relative;
}

.fairdale-logistics .choice::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #dee2e6;
  transform-origin: bottom right;
  transform: scaleX(0);
  transition: transform 0.5s ease;
}

.fairdale-logistics .choice:hover::before {
  transform-origin: bottom left;
  transform: scaleX(1);
}

.fairdale-logistics .custom-modal {
  width: 750px;
}

.lightbox {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: table;
  table-layout: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.lightbox-inner {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.lightbox-inner > .content {
  display: inline-block;
  outline: none;
  text-align: left;
}

.btn:focus {
  box-shadow: none !important;
}

.jumbotronBlock {
  padding: 10rem 2rem;
}

.ink-page {
  background-color: #f2f2f2;
  color: #222;
  font-family: "Open Sans", sans-serif;

  .slide.selected,
  .carousel.carousel-slider .slide {
    background-color: #f2f2f2;
  }

  .nav-tabs {
    position: sticky;
    top: 0px;
    background-color: #f2f2f2;
    z-index: 2;
    .active {
      border-bottom: 3px solid #2ba6cb;
      padding-bottom: 2px;
    }
  }

  .user-links {
    ul {
      list-style-type: none;
      li {
        display: inline;
        a {
          color: #5a5a5a;
        }
      }
    }
  }

  .bordered-link {
    border: 3px solid #0096d6;
    padding: 20px 20px 26px 20px;
    a {
      text-decoration: none;
      color: #222;
    }
  }
  .bordered-link:hover {
    background-color: #0096d6;
    color: white;

    a {
      color: white;
    }
  }
}

@media (max-width: 990px) {
  .udi-page .default-info {
    font-size: 11px;
  }

  .udi-page .default-info.table th {
    padding: 4px 2px 0px !important;
  }

  .fairdale-logistics .custom-modal {
    width: 650px;
  }
}

@media (max-width: 768px) {
  .fairdale-logistics .custom-modal {
    width: 93%;
  }
}
